// 考题模块
import { request, noTimeOutReq } from '@/utils/request.js'

// 下载考点excel
export function downloadPlace () {
  return request({
    url: `/admin/excel/exam/place/excel/download`,
    method: 'post',
  })
}

// 考点excel导入
export function importExcelPlace (data) {
  return noTimeOutReq({
    url: `/admin/excel/exam/place/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
// 下载学校excel
export function downloadSchool () {
  return request({
    url: `/admin/excel/school/excel/download`,
    method: 'post',
  })
}

// 学校excel导入
export function importExcelSchool (data) {
  return noTimeOutReq({
    url: `/admin/excel/school/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 下载学生excel
export function downloadStudent () {
  return request({
    url: `/admin/excel/student/excel/download`,
    method: 'post',
  })
}

// 学生excel导入
export function importExcelStudent (data) {
  return noTimeOutReq({
    url: `/admin/excel/student/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
// 下载考题excel
export function downloadQuestion () {
  return request({
    url: `/admin/excel/test/question/excel/download`,
    method: 'post',
  })
}

// 考题excel导入
export function importExcelQuestion (data) {
  return noTimeOutReq({
    url: `/admin/excel/test/question/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
// 下载用户excel
export function downloadAdmin () {
  return request({
    url: `/admin/excel/userAdmin/excel/download`,
    method: 'post',
  })
}

// 用户excel导入
export function importExcelAdmin (data) {
  return noTimeOutReq({
    url: `/admin/excel/user/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}


// 查询考题是否被考试占用
export function occupy (data) {
  return request({
    url: `/exam/test/occupy`,
    method: 'post',
    data
  })
}