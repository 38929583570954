import { request, noTimeOutReq } from "@/utils/request.js";

//展示全部级的信息
export function getAllArea(data) {
  return request({
    url: `/sys/org/get/root/or`,
    method: "post",
    data,
  });
}

//展示区域筛选框
export function getAreaSite() {
  return request({
    url: `/sys/org/area/root/get`,
    method: "post",
  });
}

//展示某个地区下面所有的考点
export function getExamSite(pageIndex, pageSize, data) {
  return request({
    url: `/sys/org/exam/place/area/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

//展示某个考点下面所有的考场(考点教室)
export function getExamRoom(sysOrgExamPlaceId, data) {
  return request({
    url: `/sys/org/exam/place/room/place/${sysOrgExamPlaceId}/list`,
    method: "post",
    data,
  });
}

//新增考点
export function addExamPlace(data) {
  return request({
    url: `/sys/org/exam/place/add`,
    method: "post",
    data,
  });
}

//新增考场(考点教室)
export function addPlaceRoom(data) {
  return request({
    url: `/sys/org/exam/place/room/add`,
    method: "post",
    data,
  });
}

//删除考点
export function delExamPlace(data) {
  return request({
    url: `/sys/org/exam/place/delete`,
    method: "post",
    data,
  });
}

//删除考场(考点教室)
export function delPlaceRoom(data) {
  return request({
    url: `/sys/org/exam/place/room/delete`,
    method: "post",
    data,
  });
}

//编辑考点
export function editExamPlace(data) {
  return request({
    url: `/sys/org/exam/place/update`,
    method: "post",
    data,
  });
}

//编辑考场
export function editExamRoom(data) {
  return request({
    url: `/sys/org/exam/place/room/update`,
    method: "post",
    data,
  });
}

//查询某个地区下的所有学校
export function queryAllSchool(data) {
  return request({
    url: `/sys/org/get/or`,
    method: "post",
    data,
  });
}

// 编辑考场分组
export function editExamRoomGroup(data) {
  return request({
    url: `/sys/org/exam/place/room/group/update`,
    method: "post",
    data,
  });
}

// 获取考场分组
export function getExamRoomGroupInfo(sysOrgExamPlaceId) {
  return request({
    url: `/sys/org/exam/place/room/place/group/${sysOrgExamPlaceId}/list`,
    method: "post",
  });
}

//展示某个地区下面所有未绑定考点的【学校】
export function getSchoolNotExamList(data) {
  return request({
    url: `/sys/org/school/not/exam/place/area/list`,
    method: "post",
    data,
  });
}

// 展示某个考点下面所有【功能室】,分页
export function getFunctionRoomListApi(pageIndex, pageSize,data) {
  return request({
    url: `/sys/org/exam/place/room/function/place/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data
  });
}

// 删除【功能室】,慎用
export function deleteFunctionRoomApi(data) {
  return request({
    url: `/sys/org/exam/place/room/function/delete`,
    method: "post",
    data,
  });
}

// 获取【功能室】详情
export function getFunctionRoomDetailApi(sysOrgExamPlaceRoomFunctionId) {
  return request({
    url: `/sys/org/exam/place/room/function/get`,
    method: "post",
    params: {
      sysOrgExamPlaceRoomFunctionId,
    },
  });
}

// 新增【功能室】
export function addFunctionRoomApi(data) {
  return request({
    url: `/sys/org/exam/place/room/function/add`,
    method: "post",
    data,
  });
}

// 修改【功能室】
export function updateFunctionRoomApi(data) {
  return request({
    url: `/sys/org/exam/place/room/function/update`,
    method: "post",
    data,
  });
}

// 获取【考场、功能室】代理流地址
export function getProxyStreamApi(data) {
  return request({
    url: `/sys/org/exam/place/room/get/proxy/stream/url`,
    method: "post",
    data,
  });
}

