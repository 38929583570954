<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        icon="el-icon-circle-plus-outline"
        type="primary"
        @click="addSite"
        size="medium"
        >添加</el-button
      >
      <!-- <el-upload
        style="display:inline-block;margin:0 10px"
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="uploadBtn"
        :show-file-list="false"
      >
        <el-button v-throttle  size="medium" type="primary" icon="el-icon-upload2">导入</el-button>
      </el-upload>-->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        icon="el-icon-upload2"
        >导入</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="downloadBtn"
        icon="el-icon-download"
        >下载导入模板</el-button
      >
      <el-button
        v-throttle
        icon="el-icon-delete"
        type="danger"
        size="medium"
        @click="batchDel"
        >批量删除</el-button
      >
    </el-row>
    <el-row>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="名称" class="formItemBoxStyle">
          <el-input v-model="formInline.name" @blur="areaScreen(1)"></el-input>
        </el-form-item>
        <el-form-item label="县区" class="formItemBoxStyle">
          <el-select
            v-model="formInline.areaValue"
            placeholder="全部"
            @change="areaScreen(1)"
          >
            <el-option
              v-for="item in areaSelectData"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试科目" class="formItemBoxStyle">
          <el-select
            v-model="formInline.subValue"
            placeholder="全部"
            @change="areaScreen(1)"
          >
            <el-option
              v-for="item in subOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="getExamSiteList"
            >查询</el-button
          >
          <el-button v-throttle size="medium" @click="resetScreen"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!--表格  -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      header-align="center"
      :header-cell-style="{ background: '#DFE6EC' }"
      border
      stripe
      @selection-change="handleSelectionChange"
      v-loading="loadingTable"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <!-- <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>-->
      <el-table-column
        align="center"
        prop="name"
        label="名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="code"
        label="代码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgAreaName"
        label="区县"
      ></el-table-column>
      <el-table-column align="center" label="考场科目">
        <template slot-scope="scope">{{
          scope.row.subjectTypeList | subjectFilter
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="examPlaceSeatCount"
        label="座位总数"
        width="110"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="联系人"
        width="140"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="联系电话"
        width="140"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="450px">
        <template slot-scope="scope">
          <el-button
            v-throttle
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            type="success"
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            v-throttle
            size="mini"
            @click="PlaceRoomEdit(scope.row)"
            type="primary"
            icon="el-icon-edit"
            >编辑考场</el-button
          >
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-edit"
            v-throttle
            @click="handleEditPlaceRoomBtnClick(scope.row)"
            >功能室编辑</el-button
          >
          <el-button
            v-throttle
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="numOfPage"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="sizeOfPage"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
    ></el-pagination>
    <!-- 添加考点弹框 -->
    <el-dialog
      class="addPlace"
      title="添加考点"
      :visible.sync="dialogAddSite"
      width="40%"
      v-if="dialogAddSite"
    >
      <el-form
        :model="addPlaceForm"
        :rules="rules"
        ref="addPlaceForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="areaName">
            <el-input
              v-model="addPlaceForm.areaName"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码" prop="areaId">
            <el-input
              v-model="addPlaceForm.areaId"
              placeholder
              size="small"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="区县" prop="areaValue">
            <el-select
              v-model="addPlaceForm.areaValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
              @change="querySchool"
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="addPlaceForm.addLng"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeAddLng"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="addPlaceForm.addLat"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeAddLat"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="addAdress">
            <el-input
              v-model="addPlaceForm.addAdress"
              placeholder
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联学校">
            <el-switch
              v-model="relSchool"
              active-color="#13ce66"
              inactive-color="#EAECF0"
              @change="relevance"
            ></el-switch>
          </el-form-item>
          <el-form-item label="主考学校" v-show="isShow" prop="checkPass">
            <el-select
              clearable
              v-model="schoolValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in schoolOptions"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
                :disabled="!!~schoolValueOther.indexOf(item.sysOrgSchoolId)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他学校" v-show="isShow" prop="checkPass">
            <el-select
              multiple
              v-model="schoolValueOther"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in schoolOptions"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
                :disabled="schoolValue == item.sysOrgSchoolId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input
              v-model="addPlaceForm.userName"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="addPlaceForm.userPhone"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="addPlace('addPlaceForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogAddSite = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加考场弹框 -->
    <el-dialog
      class="addPlace"
      title="添加考场"
      :visible.sync="dialogAddRoom"
      :close-on-click-modal="false"
      width="40%"
      v-if="dialogAddRoom"
    >
      <el-form
        :model="addRoomForm"
        :rules="rules"
        ref="addRoomForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="roomName">
            <el-input
              v-model="addRoomForm.roomName"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码" prop="roomId">
            <el-input
              v-model="addRoomForm.roomId"
              placeholder
              size="small"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="科目">
            <el-select
              size="small"
              v-model="value"
              placeholder="请选择科目"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="座位数量">
            <el-input
              v-model="addRoomForm.seatTotal"
              placeholder
              size="small"
              oninput="value=value.replace(/[^0-9]/g,'');if(value>81)value=81;"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="座位布局">
            <el-button
              v-throttle
              type="primary"
              size="small"
              style="margin-left: 15px; width: 100px; margin-top: 0"
              @click="openSeatEdit"
              >设置</el-button
            >
          </el-form-item>
          <el-form-item label="媒体流">
            <el-input
              v-model="addRoomForm.media"
              placeholder
              size="small"
              maxlength="225"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="addRoomForm.remarks"
              placeholder
              size="small"
              maxlength="225"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="addExamPlaceRoom('addRoomForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogAddRoom = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑考点 -->
    <el-dialog
      class="addPlace"
      title="编辑考点"
      :visible.sync="dialogEditSite"
      width="40%"
      v-if="dialogEditSite"
    >
      <el-form
        :model="editPlaceForm"
        :rules="rules"
        ref="editPlaceForm"
        label-width="100px"
        class="demo-ruleForm"
        v-loading="editSiteLoading"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="editAreaName">
            <el-input
              v-model="editPlaceForm.editAreaName"
              placeholder
              size="small"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码" prop="editAreaId">
            <el-input
              v-model="editPlaceForm.editAreaId"
              placeholder
              size="small"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="区县">
            <el-select
              v-model="editAreaValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
              @change="editQuerySchool"
            >
              <el-option
                v-for="item in editAreaOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="editPlaceForm.editLng"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeEditLng"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="editPlaceForm.editLat"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeEditLat"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="editAdress">
            <el-input
              v-model="editPlaceForm.editAdress"
              placeholder
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联学校">
            <el-switch
              v-model="editRelSchool"
              active-color="#13ce66"
              inactive-color="#EAECF0"
              @change="editRelevance"
            ></el-switch>
          </el-form-item>
          <el-form-item
            label="主考学校"
            v-show="editIsShow"
            prop="editCheckPass"
          >
            <el-select
              clearable
              v-model="editSchoolValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in editSchoolOptions"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
                :disabled="!!~editSchoolValueOther.indexOf(item.sysOrgSchoolId)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="其他学校"
            v-show="editIsShow"
            prop="editCheckPass"
          >
            <el-select
              multiple
              v-model="editSchoolValueOther"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in editSchoolOptions"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
                :disabled="editSchoolValue == item.sysOrgSchoolId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input
              v-model="editPlaceForm.editUserName"
              placeholder
              size="small"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="editPlaceForm.editUserPhone"
              placeholder
              size="small"
              maxlength="18"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="preEditSite('editPlaceForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogEditSite = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 功能室设置弹框 -->
    <el-dialog :visible.sync="placeRoomDialogVisible" width="700px">
      <div class="placeRoomDialog-title">功能室设置</div>
      <el-row>
        <el-table
          :data="placeRoomTableData"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="功能室" width="400px">
            <template slot-scope="scoped">
              <div style="position: relative">
                <el-input
                  v-model="scoped.row.name"
                  disabled
                  style="width: 320px"
                ></el-input>
                <div
                  class="edit-icon"
                  @click="
                    handleEditPlaceRoomDetailBtnClick(scoped.$index, scoped.row)
                  "
                >
                  <i class="el-icon-edit"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="scoped">
              <el-switch
                v-model="scoped.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <span
                style="color: red; cursor: pointer"
                @click="handleDeletePlaceRoomBtnClick(scoped.$index)"
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            padding: 20px 0;
            padding-left: 10px;
          "
        >
          <span
            style="color: #00f; text-decoration: underline; cursor: pointer"
            @click="handleAddPlaceRoomBtnClick"
            >+ 新增功能室</span
          >
        </div>
      </el-row>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="handleCancelPlaceRoomBtnClick">取消</el-button>
        <el-button type="primary" @click="handleSavePlaceRoomBtnClick"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="addOrEditPlaceRoom === 1 ? '新增功能室' : '编辑功能室'"
      :visible.sync="addOrEditPlaceRoomDialogVisible"
      width="450px"
      top="10%"
    >
      <el-form
        class="addOrEditPlaceRoomForm"
        :model="addOrEditPlaceRoomForm"
        ref="addOrEditPlaceRoomForm"
        :rules="rules"
      >
        <el-form-item
          prop="placeRoomName"
          label="功能室名称"
          style="margin-left: -3px"
        >
          <el-input
            v-model="addOrEditPlaceRoomForm.placeRoomName"
            placeholder="请输入"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item prop="mainOneMediaUrl" label="媒体流1主流">
          <el-input
            v-model="addOrEditPlaceRoomForm.mainOneMediaUrl"
            placeholder="请输入"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item prop="assistOneMediaUrl" label="媒体流1辅流">
          <el-input
            v-model="addOrEditPlaceRoomForm.assistOneMediaUrl"
            placeholder="请输入"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item prop="mainTwoMediaUrl" label="媒体流2主流">
          <el-input
            v-model="addOrEditPlaceRoomForm.mainTwoMediaUrl"
            placeholder="请输入"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item prop="assistTwoMediaUrl" label="媒体流2辅流">
          <el-input
            v-model="addOrEditPlaceRoomForm.assistTwoMediaUrl"
            placeholder="请输入"
            style="width: 300px"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button @click="handleCancelEditPlaceRoomBtnClick">取消</el-button>
        <el-button type="primary" @click="handleSaveEditPlaceRoomBtnClick"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>

<script>
import { downloadPlace, importExcelPlace } from '@/api/importExport.js'
import {
  getExamSite,
  getExamRoom,
  getAreaSite,
  addPlaceRoom,
  delPlaceRoom,
  addExamPlace,
  getAllArea,
  delExamPlace,
  editExamPlace,
  editExamRoom,
  queryAllSchool,
  getSchoolNotExamList,
  getFunctionRoomListApi,
  deleteFunctionRoomApi,
  getFunctionRoomDetailApi,
  addFunctionRoomApi,
  updateFunctionRoomApi,
} from '@/api/mechanism/examArea.js'
export default {
  // 考点管理
  name: 'examPlaceManage',
  data() {
    var checkPass = (rule, value, callback) => {
      if (this.schoolValue === '' && this.relSchool == true) {
        callback(new Error('请选择关联学校'))
      } else {
        callback()
      }
    }
    var editCheckPass = (rule, value, callback) => {
      if (this.editSchoolValue === '' && this.editRelSchool == true) {
        callback(new Error('请选择关联学校'))
      } else {
        callback()
      }
    }
    return {
      uploadMsg: '',
      uploadLoading: false,
      editSiteLoading: false,
      //首页顶部区域下拉框(需要'全部'字段)
      subOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '物理',
        },
        {
          value: 2,
          label: '生物',
        },
        {
          value: 3,
          label: '化学',
        },
      ],

      //编辑页内区域下拉框
      options: [
        {
          value: 1,
          label: '物理',
        },
        {
          value: 2,
          label: '生物',
        },
        {
          value: 3,
          label: '化学',
        },
      ],
      //分页
      pageTotal: 0,
      //每页条数公共变量
      sizeOfPage: 10,
      //当前页数
      numOfPage: 1,
      //添加考点区县选择窗口
      areaOptions: [],
      //编辑考点区县选择窗口
      editAreaOptions: [],
      //添加考点区县关联学校选择窗口
      schoolOptions: [],
      schoolValue: '',
      schoolValueOther: [],

      //编辑考点区县关联学校选择窗口
      editSchoolOptions: [],
      editSchoolValue: '',
      editSchoolValueOther: [],

      value: 1,
      editAreaValue: '',
      formInline: {
        name: '',
        areaValue: 0,
        subValue: 0,
      },
      areaSelectData: [],
      rules: {
        roomName: [
          { required: true, message: '请输入考场名称', trigger: 'blur' },
        ],
        roomId: [
          { required: true, message: '请输入考场代码', trigger: 'blur' },
        ],
        areaName: [
          { required: true, message: '请输入考点名称', trigger: 'blur' },
        ],
        areaId: [{ required: true, message: '请输入代码', trigger: 'blur' }],
        areaValue: [
          { required: true, message: '请选择区县', trigger: 'change' },
        ],
        editAreaName: [
          { required: true, message: '请输入考点名称', trigger: 'blur' },
        ],
        editAreaId: [
          { required: true, message: '请输入代码', trigger: 'blur' },
        ],
        editRoomName: [
          { required: true, message: '请输入考场名称', trigger: 'blur' },
        ],
        editRoomId: [
          { required: true, message: '请输入考场代码', trigger: 'blur' },
        ],
        checkPass: [{ validator: checkPass, trigger: 'blur' }],
        editCheckPass: [{ validator: editCheckPass, trigger: 'blur' }],
        addLat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        addLng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        addAdress: [
          { required: true, message: '请输入通讯地址', trigger: 'blur' },
        ],
        editLat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        editLng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        editAdress: [
          { required: true, message: '请输入通讯地址', trigger: 'blur' },
        ],
        placeRoomName: [
          { required: true, message: '请输入功能室名称', trigger: 'blur' },
        ],
      },
      tableData: [],
      examRoom: '',
      //编辑考场时的某个主键
      editPlaceId: '',
      //弹窗开关变量
      dialogAddSite: false,
      dialogAddRoom: false,
      dialogEditSite: false,
      dialogEditPlaceRoom: false,
      dialogEditRoom: false,
      dialogEditSeat: false,
      //添加考点-Site窗口
      addPlaceForm: {
        areaName: '',
        areaId: '',
        areaValue: '',
        position: '',
        userName: '',
        userPhone: '',
        addLng: '',
        addLat: '',
        addAdress: '',
      },
      //添加考场-Room窗口
      addRoomForm: {
        roomName: '',
        roomId: '',
        subject: '',
        seatTotal: 16,
        media: '',
        remarks: '',
      },
      //编辑考点
      editPlaceForm: {
        editAreaName: '',
        editAreaId: '',
        editPosition: '',
        editUserName: '',
        editUserPhone: '',
        /*公共变量*/
        //sysOrgExamPlaceId
        examPlaceId: '',
        editLng: '',
        editLat: '',
        editAdress: '',
      },
      //编辑考场信息
      editRoomInfo: {
        editRoomName: '',
        editRoomId: '',
        editSeatTotal: '',
        editMedia: '',
        editRemarks: '',
        /*公共变量*/
        sysPlaceID: '',
        sysRoomId: '',
        seatArrange: [],
      },
      //是否关联学校checkbox框
      relSchool: false,
      //是否关联学校checkbox框(编辑)
      editRelSchool: false,
      //是否显示关联学校的选择框
      isShow: false,
      //是否显示关联学校的选择框(编辑)
      editIsShow: false,
      //考场座位布局
      setSeatForm: {
        setSeatRow: 4,
        setSeatCol: 4,
      },
      // seatInput: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'],
      seatInput: [],
      isDisabled: [],
      inputBorder: [],
      //报错数组
      errorList: [],
      //请求参数 座位布局二维数组
      seatLayout: [],
      //控制添加考场或编辑考场 (true是添加考场 false是编辑考场)
      editOrAdd: true,
      //是否是横向布局
      isTransverse: false,
      isVertical: false,
      istransverseSnake: false,
      isverticalSnake: false,
      //保存状态
      preState: false,
      //座位数量中间值
      medianSeatTotal: 0,
      //考场编辑座位数量变化状态
      seatNumState: false,
      loadingTable: false,
      // 功能室弹框可见
      placeRoomDialogVisible: false,
      placeRoomTableData: [],
      tempPlaceRoomTableData: [],
      addOrEditPlaceRoom: 1, // 1 表示新增功能室 0 编辑
      addOrEditPlaceRoomDialogVisible: false,
      addOrEditPlaceRoomForm: {
        placeRoomName: '',
        mainOneMediaUrl: '',
        assistOneMediaUrl: '',
        mainTwoMediaUrl: '',
        assistTwoMediaUrl: '',
      },
      sysOrgExamPlaceId: '',
      functionRoomIndex: '',
    }
  },
  filters: {
    subjectFilter(val) {
      let subject = ''
      if (val) {
        val.forEach((itme, i) => {
          if (i < val.length - 1) {
            switch (itme) {
              case 1:
                subject += '物理/'
                break
              case 2:
                subject += '生物/'
                break
              case 3:
                subject += '化学/'
                break
            }
          } else {
            switch (itme) {
              case 1:
                subject += '物理'
                break
              case 2:
                subject += '生物'
                break
              case 3:
                subject += '化学'
                break
            }
          }
        })
      }

      return subject
    },
  },
  mounted() {
    this.getExamSiteList()
    this.getSelectInfo()
    // this.pushSeatData();
  },

  watch: {
    dialogEditSeat: {
      handler(newData, oldData) {
        if (!newData && !this.preState) {
          console.log('guanbiguanbi')
          //由于是未点击保存而关闭页面 则座位数量不需要改变 通过中间值将座位数量恢复为当时的值
          this.editRoomInfo.editSeatTotal = this.medianSeatTotal
          //初始化
          this.medianSeatTotal = 0
          this.seatLayout = []
          this.pushSeatData()
          // this.seatNumState = false;
        } else {
          //初始化保存状态
          this.preState = false
        }
      },
    },
  },
  methods: {
    // 导入
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      importExcelPlace(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.data) {
            // this.$message.success('导入成功');
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.getExamSiteList()
            this.getSelectInfo()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            // this.$message.warning(res.msg)
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
    // 下载excel模板
    downloadBtn() {
      downloadPlace().then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.sizeOfPage = val
      this.getExamSiteList()
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.numOfPage = val
      this.getExamSiteList()
    },

    //输入框失去焦点时，会把value值赋值给v-model绑定变量，使两者保持一致
    salaryChangeAddLng(e) {
      this.addPlaceForm.addLng = e.target.value
    },

    salaryChangeAddLat(e) {
      this.addPlaceForm.addLat = e.target.value
    },

    salaryChangeEditLng(e) {
      this.editPlaceForm.editLng = e.target.value
    },

    salaryChangeEditLat(e) {
      this.editPlaceForm.editLat = e.target.value
    },

    //顶部地区下拉框获取数据
    getSelectInfo() {
      getAreaSite().then((res) => {
        let { code, data, msg } = res
        if (code == 200) {
          data.childrenOrg.unshift({ orgId: 0, orgName: '全部' })
          this.areaSelectData = data.childrenOrg
        } else {
          console.log('地区筛选下拉框数据获取失败')
        }
      })
    },

    //通过区域地区或者学科进行筛选
    areaScreen(type) {
      const ScreenParam = new FormData()
      ScreenParam.append('subjectType', this.formInline.subValue)
      ScreenParam.append('sysOrgAreaId', this.formInline.areaValue)
      ScreenParam.append('query', this.formInline.name)
      console.log(ScreenParam)
      //type!=2 说明是第一次进行筛选 即把页数和条数都恢复默认1/10
      //type=2 为了跳过恢复默认，每次改变页数或者条数都会触发此方法
      if (type != 2) {
        this.numOfPage = 1
        this.sizeOfPage = 10
      }

      this.loadingTable = true
      getExamSite(this.numOfPage, this.sizeOfPage, ScreenParam).then((res) => {
        let { code, data } = res
        this.loadingTable = false
        if (code == 200) {
          this.tableData = data
          this.pageTotal = res.total
        }
      })
    },

    //重置筛选-点击刷新图标按钮
    resetScreen() {
      this.formInline.subValue = 0
      this.formInline.areaValue = 0
      this.formInline.name = ''
      this.sizeOfPage = 10
      this.numOfPage = 1
      this.getExamSiteList()
    },

    //点击 添加考点 按钮(接口返回级联显示区域)
    addSite() {
      this.dialogAddSite = true
      const AllAreaParam = new FormData()
      AllAreaParam.append('getExamPlace', 0)
      AllAreaParam.append('getSchool', 0)
      //清空表单
      this.addPlaceForm.userPhone = ''
      this.addPlaceForm.userName = ''
      this.relSchool = false
      this.isShow = false
      this.schoolValue = ''
      this.schoolValueOther = []
      this.addPlaceForm.areaValue = ''
      this.addPlaceForm.areaId = ''
      this.addPlaceForm.areaName = ''
      this.addPlaceForm.addLng = ''
      this.addPlaceForm.addLat = ''
      this.addPlaceForm.addAdress = ''
      getAllArea(AllAreaParam).then((res) => {
        console.log('addSite', res)
        let { code, data, msg } = res
        if (code == 200) {
          this.areaOptions = data.childrenOrg
        } else {
          this.$message.error(msg)
        }
      })
    },

    //添加考点
    addPlace(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.relSchool) {
            var ExamPlaceParam = {
              name: this.addPlaceForm.areaName,
              code: this.addPlaceForm.areaId,
              userName: this.addPlaceForm.userName,
              sysOrgAreaId: this.addPlaceForm.areaValue,
              phone: this.addPlaceForm.userPhone,
              isSchool: 1,
              sysOrgSchoolId: this.schoolValue,
              sysOrgSchoolIdAssist: this.schoolValueOther,
              lat: this.addPlaceForm.addLat,
              lng: this.addPlaceForm.addLng,
              address: this.addPlaceForm.addAdress,
            }
          } else {
            var ExamPlaceParam = {
              name: this.addPlaceForm.areaName,
              code: this.addPlaceForm.areaId,
              userName: this.addPlaceForm.userName,
              sysOrgAreaId: this.addPlaceForm.areaValue,
              phone: this.addPlaceForm.userPhone,
              isSchool: 0,
              sysOrgSchoolId: '',
              sysOrgSchoolIdAssist: [],
              lat: this.addPlaceForm.addLat,
              lng: this.addPlaceForm.addLng,
              address: this.addPlaceForm.addAdress,
            }
          }

          addExamPlace(ExamPlaceParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              console.log(data)
              this.$message.success('添加考点成功')
              this.dialogAddSite = false
              this.getExamSiteList()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
        }
      })
    },

    //考点编辑窗口
    async handleEdit(index, row) {
      // getExamRoom(1, 10, row.sysOrgExamPlaceId).then(res => {
      //   let { code, data, message } = res
      //   console.log('examroom', data);
      //   this.examRoom = data
      // })
      //打开弹窗
      this.dialogEditSite = true
      this.editSiteLoading = true
      //拿到区县选择下拉框数组
      const AllAreaParam = new FormData()
      AllAreaParam.append('getExamPlace', 0)
      AllAreaParam.append('getSchool', 0)
      getAllArea(AllAreaParam).then((res) => {
        console.log('addSite', res)
        let { code, data, msg } = res
        if (code == 200) {
          this.editAreaOptions = data.childrenOrg
        } else {
          this.$message.error(msg)
        }
      })
      this.editPlaceForm.editAreaName = row.name
      this.editPlaceForm.editAreaId = row.code
      //为下拉框默认value赋值
      this.editAreaValue = row.sysOrgAreaId
      this.editPlaceForm.editUserName = row.userName
      this.editPlaceForm.editUserPhone = row.phone
      this.editPlaceForm.editLng = row.lng
      this.editPlaceForm.editLat = row.lat
      this.editPlaceForm.editAdress = row.address
      //关联学校
      await this.editQuerySchool(
        row.sysOrgAreaId,
        row.sysOrgSchoolId,
        row.sysOrgSchoolIdAssist
      )
      if (row.isSchool == 0) {
        this.editRelSchool = false
        this.editIsShow = false
        this.editSchoolValue = ''
        this.editSchoolValueOther = []
        this.editSiteLoading = false
      } else {
        this.editRelSchool = true
        this.editIsShow = true
        this.editSchoolValue = row.sysOrgSchoolId
        if (!!row.sysOrgSchoolIdAssist) {
          this.editSchoolValueOther = row.sysOrgSchoolIdAssist
        } else {
          this.editSchoolValueOther = []
        }
        this.editSiteLoading = false
      }
      //标记主键 -- 为保存考点编辑接口创建变量
      this.editPlaceId = row.sysOrgExamPlaceId
    },

    //考点编辑-保存
    preEditSite(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editRelSchool) {
            var editSiteParam = {
              name: this.editPlaceForm.editAreaName,
              code: this.editPlaceForm.editAreaId,
              sysOrgAreaId: this.editAreaValue,
              userName: this.editPlaceForm.editUserName,
              phone: this.editPlaceForm.editUserPhone,
              sysOrgExamPlaceId: this.editPlaceId,
              isSchool: 1,
              sysOrgSchoolId: this.editSchoolValue,
              sysOrgSchoolIdAssist: this.editSchoolValueOther,
              lat: this.editPlaceForm.editLat,
              lng: this.editPlaceForm.editLng,
              address: this.editPlaceForm.editAdress,
            }
          } else {
            var editSiteParam = {
              name: this.editPlaceForm.editAreaName,
              code: this.editPlaceForm.editAreaId,
              sysOrgAreaId: this.editAreaValue,
              userName: this.editPlaceForm.editUserName,
              phone: this.editPlaceForm.editUserPhone,
              sysOrgExamPlaceId: this.editPlaceId,
              isSchool: 0,
              sysOrgSchoolId: '',
              sysOrgSchoolIdAssist: [],
              lat: this.editPlaceForm.editLat,
              lng: this.editPlaceForm.editLng,
              address: this.editPlaceForm.editAdress,
            }
          }

          editExamPlace(editSiteParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              this.$message.success('编辑考点成功')
              this.dialogEditSite = false
              this.getExamSiteList()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          this.$message.error('请按照提示输入完整信息')
          return false
        }
      })
    },

    //编辑考场
    PlaceRoomEdit(row) {
      // 编辑考场先前的代码，有弹窗的
      // this.dialogEditPlaceRoom = true
      // this.examPlaceId = row.sysOrgExamPlaceId
      // getExamRoom(row.sysOrgExamPlaceId).then((res) => {
      //   let { code, data, msg } = res
      //   this.examRoom = data
      // })

      // 进行页面跳转
      this.$router.push({
        path: `editExamPlaceManage?examPlaceInfo=${JSON.stringify(row)}`,
      })
    },

    //表格单个删除
    handleDelete(index, row) {
      this.$confirm('确定删除该考点?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let placeList = []
          placeList.push(row.sysOrgExamPlaceId)
          delExamPlace(placeList).then((res) => {
            let { success, data, msg } = res
            if (success) {
              this.getExamSiteList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {})
    },

    //表格批量删除
    batchDel() {
      if (this.$refs.multipleTable.selection.length > 0) {
        this.$confirm('确定删除勾选的考点?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            var delDataList = []
            this.$refs.multipleTable.selection.forEach((Ele, index) => {
              delDataList.push(Ele.sysOrgExamPlaceId)
            })
            delExamPlace(delDataList).then((res) => {
              let { success, data, msg } = res
              if (success) {
                this.getExamSiteList()
                this.$message({
                  type: 'success',
                  message: '批量删除成功!',
                })
              } else {
                this.$message.error(msg)
              }
            })
          })
          .catch(() => {})
      } else {
        this.$message.error('请先勾选需要删除的考点')
      }
    },

    //考点信息分页查询
    getExamSiteList() {
      if (
        this.formInline.areaValue == '' &&
        this.formInline.subValue == '' &&
        this.formInline.name == ''
      ) {
        this.loadingTable = true
        getExamSite(this.numOfPage, this.sizeOfPage).then((res) => {
          let { code, data, msg } = res
          this.loadingTable = false
          if (code == '200') {
            // console.log(data);
            this.tableData = data
            this.pageTotal = res.total
          }
        })
      } else {
        this.areaScreen(2)
      }
    },

    //考场管理删除考场卡片
    delCard(index, value) {
      let roomList = []
      roomList.push(value.sysOrgExamPlaceRoomId)
      delPlaceRoom(roomList).then((res) => {
        let { success, data, msg } = res
        if (success) {
          this.$message.success('删除考场成功')
          this.examRoom.splice(index, 1)
        } else {
          this.$message.error(msg)
        }
      })
    },

    //添加考场按钮-打开添加考场弹窗
    addRoom() {
      this.dialogAddRoom = true
      //清空表单
      this.addRoomForm.remarks = ''
      this.addRoomForm.media = ''
      this.addRoomForm.seatTotal = 16
      this.addRoomForm.roomId = ''
      this.addRoomForm.roomName = ''
      this.editOrAdd = true
      this.setSeatForm.setSeatRow = 4
      this.setSeatForm.setSeatCol = 4
    },

    //添加考场(考点教室)
    addExamPlaceRoom(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          const PlaceRoomParam = {
            name: this.addRoomForm.roomName,
            code: this.addRoomForm.roomId,
            subjectType: this.value,
            seatCount: this.addRoomForm.seatTotal,
            mainMediaUrl: this.addRoomForm.media,
            sysOrgExamPlaceId: this.examPlaceId,
            remark: this.addRoomForm.remarks,
            seatArrange: this.seatLayout,
          }
          addPlaceRoom(PlaceRoomParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              this.$message.success('添加考场成功')
              this.dialogAddRoom = false
              getExamRoom(this.examPlaceId).then((res) => {
                let { code, data, msg } = res
                this.examRoom = data
              })
              this.getExamSiteList()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          return false
        }
      })
    },

    //编辑考场信息-打开编辑窗口
    editExamRoomInfo(val) {
      this.dialogEditRoom = true
      console.log(val, '111')
      this.editRoomInfo.editRoomName = val.name
      this.editRoomInfo.editRoomId = val.code
      this.editRoomInfo.editSeatTotal = val.seatCount
      this.value = val.subjectType
      this.editRoomInfo.editMedia = val.mainMediaUrl
      this.editRoomInfo.editRemarks = val.remark
      this.editRoomInfo.seatArrange = val.seatArrange
      // 为下面接口提供参数
      this.editRoomInfo.sysPlaceID = val.sysOrgExamPlaceId
      this.editRoomInfo.sysRoomId = val.sysOrgExamPlaceRoomId
      this.editOrAdd = false
      //打开编辑考场窗口则清空座位布局的暂存数据
      this.seatLayout = []
      console.log(val)
    },

    //编辑考场信息-点击保存
    editSchoolClass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //处理暂存数组seatLayout，用999作为占位符 不允许存在空值和null
          for (let i = 0; i < this.seatLayout.length; i++) {
            if (this.seatLayout.length < this.setSeatForm.setSeatCol) {
              let completedNum =
                this.setSeatForm.setSeatCol - this.seatLayout[i].length
              for (let j = 0; j < completedNum; j++) {
                this.seatLayout[i].push(999)
              }
            }
          }
          for (let i = 0; i < this.seatLayout.length; i++) {
            for (let j = 0; j < this.seatLayout[i].length; j++) {
              if (
                this.seatLayout[i][j] === null ||
                this.seatLayout[i][j] === ''
              ) {
                this.seatLayout[i][j] = 999
              }
            }
          }
          let editRoomParam = {
            name: this.editRoomInfo.editRoomName,
            code: this.editRoomInfo.editRoomId,
            seatCount: this.editRoomInfo.editSeatTotal,
            subjectType: this.value,
            mainMediaUrl: this.editRoomInfo.editMedia,
            sysOrgExamPlaceId: this.editRoomInfo.sysPlaceID,
            sysOrgExamPlaceRoomId: this.editRoomInfo.sysRoomId,
            remark: this.editRoomInfo.editRemarks,
            seatArrange: this.seatLayout,
          }
          editExamRoom(editRoomParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              getExamRoom(this.examPlaceId).then((res) => {
                let { code, data, msg } = res
                this.examRoom = data
              })
              this.$message.success('编辑考场成功！')
              this.dialogEditRoom = false
              this.getExamSiteList()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          return false
        }
      })
    },

    //添加考点-区域下拉框联动学校
    querySchool(value) {
      console.log(value)
      const queryParam = new FormData()
      // queryParam.append('getExamPlace', 0)
      // queryParam.append('getSchool', 1)
      // queryParam.append('sysOrgAreaId', value)
      // queryAllSchool(queryParam).then((res) => {
      //   let { code, data } = res
      //   if (!!data.childrenOrg.length) {
      //     this.schoolOptions = data.childrenOrg
      //     this.schoolValue = data.childrenOrg[0].orgId
      //     console.log(this.schoolValue, 'schoolvalue')
      //   } else {
      //     this.schoolValue = ''
      //     this.schoolOptions = []
      //     console.log(this.schoolValue, 'schoolvalue')
      //   }
      // })
      // queryParam.append('excludeSysOrgSchoolIdList', [])
      // queryParam.append('includeSysOrgSchoolIdList', [])
      queryParam.append('sysOrgAreaId', value)
      getSchoolNotExamList(queryParam)
        .then((res) => {
          this.schoolValue = ''
          this.schoolValueOther = []
          this.schoolOptions = []
          console.log('res', res)
          if (res.success) {
            this.schoolOptions = res.data
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },

    //编辑考点--区域下拉框联动学校
    async editQuerySchool(value, schoolId, idList) {
      console.log(schoolId, '111', idList)
      const queryParam = new FormData()
      // queryParam.append('getExamPlace', 0)
      // queryParam.append('getSchool', 1)
      // queryParam.append('sysOrgAreaId', value)
      // await queryAllSchool(queryParam).then((res) => {
      //   let { code, data } = res
      //   if (data.childrenOrg.length > 0) {
      //     if (schoolId != null) {
      //       this.editSchoolOptions = data.childrenOrg
      //       var idx = data.childrenOrg.findIndex(function (event) {
      //         return event.orgId === schoolId
      //       })
      //       this.editSchoolValue = data.childrenOrg[idx].orgId
      //     } else {
      //       this.editSchoolOptions = data.childrenOrg
      //       this.editSchoolValue = data.childrenOrg[0].orgId
      //     }
      //   } else {
      //     this.editSchoolValue = ''
      //     this.editSchoolOptions = []
      //   }
      // })
      if (idList != null && idList.length > 0) {
        queryParam.append('includeSysOrgSchoolIdList', [schoolId, ...idList])
      } else if (!!schoolId) {
        queryParam.append('includeSysOrgSchoolIdList', schoolId)
      }
      queryParam.append('sysOrgAreaId', value)
      await getSchoolNotExamList(queryParam)
        .then((res) => {
          this.editSchoolValue = ''
          this.editSchoolValueOther = []
          this.editSchoolOptions = []
          console.log('res', res)
          if (res.success) {
            this.editSchoolOptions = res.data
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },

    //添加考点-关联学校checkbox框
    relevance(value) {
      console.log(value)
      if (value == true) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },

    editRelevance(value) {
      if (value == true) {
        this.editIsShow = true
      } else {
        this.editIsShow = false
      }
    },

    // 功能室编辑按钮点击事件
    handleEditPlaceRoomBtnClick(row) {
      // this.sysOrgExamPlaceId = row.sysOrgExamPlaceId;
      // getFunctionRoomListApi(row.sysOrgExamPlaceId).then((res) => {
      //   if (res.success) {
      //     this.placeRoomTableData = res.data;
      //     this.tempPlaceRoomTableData = [...res.data];
      //     this.placeRoomDialogVisible = true;
      //   } else {
      //     this.$message.error(res.msg);
      //     return;
      //   }
      // });

      // 进行页面跳转
      this.$router.push({
        path: `functionRoom?examPlaceInfo=${JSON.stringify(row)}`,
      })
    },
    // 功能室删除按钮点击事件
    handleDeletePlaceRoomBtnClick(index) {
      this.placeRoomTableData.splice(index, 1)
    },
    // 功能室新增按钮点击事件
    handleAddPlaceRoomBtnClick() {
      this.addOrEditPlaceRoom = 1
      this.addOrEditPlaceRoomForm = {
        placeRoomName: '',
        mainOneMediaUrl: '',
        assistOneMediaUrl: '',
        mainTwoMediaUrl: '',
        assistTwoMediaUrl: '',
      }
      this.addOrEditPlaceRoomDialogVisible = true
    },
    // 功能室编辑按钮点击
    handleEditPlaceRoomDetailBtnClick(index, row) {
      this.addOrEditPlaceRoom = 0
      this.addOrEditPlaceRoomForm = {
        placeRoomName: row.placeRoomName,
        mainOneMediaUrl: row.mainOneMediaUrl,
        assistOneMediaUrl: row.assistOneMediaUrl,
        mainTwoMediaUrl: row.mainTwoMediaUrl,
        assistTwoMediaUrl: row.assistTwoMediaUrl,
      }
      this.functionRoomIndex = index
      this.addOrEditPlaceRoomDialogVisible = true
    },
    // 功能室设置取消按钮
    handleCancelPlaceRoomBtnClick() {
      this.placeRoomDialogVisible = false
    },
    // 功能室设置保存按钮
    handleSavePlaceRoomBtnClick() {
      // 这里进行编辑和新增操作
      let requestResult = true
      for (let i = 0; i < this.placeRoomTableData.length; i++) {
        if (this.placeRoomTableData[i].sysOrgExamPlaceRoomFunctionId) {
          // 进入此if,进行编辑操作
          let editParams = {
            assistMediaUrl: this.placeRoomTableData[i].assistOneMediaUrl,
            assistMediaUrl2: this.placeRoomTableData[i].assistTwoMediaUrl,
            mainMediaUrl: this.placeRoomTableData[i].mainOneMediaUrl,
            mainMediaUrl2: this.placeRoomTableData[i].mainTwoMediaUrl,
            name: this.placeRoomTableData[i].name,
            status: Number(this.placeRoomTableData[i].status),
            sysOrgExamPlaceId: this.sysOrgExamPlaceId,
            sysOrgExamPlaceRoomFunctionId:
              this.placeRoomTableData[i].sysOrgExamPlaceRoomFunctionId,
          }
          updateFunctionRoomApi(editParams).then((res) => {
            if (res.success) {
            } else {
              requestResult = false
              this.$message.error(res.msg)
              return
            }
          })
        } else {
          // 进入此if,进行新增操作
          let addParams = {
            assistMediaUrl: this.placeRoomTableData[i].assistOneMediaUrl,
            assistMediaUrl2: this.placeRoomTableData[i].assistTwoMediaUrl,
            mainMediaUrl: this.placeRoomTableData[i].mainOneMediaUrl,
            mainMediaUrl2: this.placeRoomTableData[i].mainTwoMediaUrl,
            name: this.placeRoomTableData[i].name,
            status: Number(this.placeRoomTableData[i].status),
            sysOrgExamPlaceId: this.sysOrgExamPlaceId,
          }
          addFunctionRoomApi(addParams).then((res) => {
            if (res.success) {
            } else {
              requestResult = false
              this.$message.error(res.msg)
              return
            }
          })
        }
      }
      if (this.tempPlaceRoomTableData.length > 0) {
        let idList = []
        this.tempPlaceRoomTableData.forEach((item1) => {
          let result = true
          this.placeRoomTableData.forEach((item2) => {
            if (
              item2.sysOrgExamPlaceRoomFunctionId &&
              item1.sysOrgExamPlaceRoomFunctionId ===
                item2.sysOrgExamPlaceRoomFunctionId
            ) {
              result = false
            }
          })
          // 找不到,表示删掉了
          if (result) {
            idList.push(item1.sysOrgExamPlaceRoomFunctionId)
          }
        })
        if (idList.length > 0) {
          deleteFunctionRoomApi(idList).then((res) => {
            if (res.success) {
            } else {
              requestResult = false
              this.$message.error(res.msg)
              return
            }
          })
        }
      }
      setTimeout(() => {
        if (requestResult) {
          this.$message.success('保存成功!')
          this.placeRoomDialogVisible = false
        }
      }, 100)
    },
    // 新增或编辑功能室取消按钮
    handleCancelEditPlaceRoomBtnClick() {
      this.addOrEditPlaceRoomDialogVisible = false
    },
    // 新增或编辑功能室保存按钮
    handleSaveEditPlaceRoomBtnClick() {
      this.$refs.addOrEditPlaceRoomForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditPlaceRoom === 1) {
            this.placeRoomTableData.push({
              ...this.addOrEditPlaceRoomForm,
              status: true,
              name: this.addOrEditPlaceRoomForm.placeRoomName,
              sysOrgExamPlaceId: this.sysOrgExamPlaceId,
            })
            this.addOrEditPlaceRoomDialogVisible = false
          } else {
            let obj = this.placeRoomTableData[this.functionRoomIndex]
            ;(obj.placeRoomName = this.addOrEditPlaceRoomForm.placeRoomName),
              (obj.mainOneMediaUrl =
                this.addOrEditPlaceRoomForm.mainOneMediaUrl),
              (obj.assistOneMediaUrl =
                this.addOrEditPlaceRoomForm.assistOneMediaUrl),
              (obj.mainTwoMediaUrl =
                this.addOrEditPlaceRoomForm.mainTwoMediaUrl),
              (obj.assistTwoMediaUrl =
                this.addOrEditPlaceRoomForm.assistTwoMediaUrl),
              (obj.name = this.addOrEditPlaceRoomForm.placeRoomName),
              (obj.sysOrgExamPlaceId = this.sysOrgExamPlaceId.name),
              (this.addOrEditPlaceRoomDialogVisible = false)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.blue {
  border: 1px solid #1890ff;
  background-color: #1890ff;
  border-radius: 5px;
}
.green {
  border: 1px solid #12c1b0;
  background-color: #12c1b0;
  border-radius: 5px;
}
.purple {
  border: 1px solid #2c4464;
  background-color: #2c4464;
  border-radius: 5px;
}
.demo-form-inline {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 20px;
}
.addPlace {
  .addAll {
    div:not(:first-child) {
      margin-top: 10px;
    }
    .el-button {
      margin-top: 2%;
      margin-left: 11%;
    }
  }
  .spanText {
    display: inline-block;
    text-align: right;
    width: 70px;
  }
  .el-input {
    width: 85%;
    margin-left: 15px;
  }
  .el-switch {
    margin-left: 15px;
  }
}
.special {
  .outLayer {
    height: 250px;
    width: 85%;
    border: solid 1px #797979;
    display: inline-block;
    margin-left: 15px;
    overflow: auto;
  }
  .outLayer::-webkit-scrollbar {
    width: 5px;
  }
  .roomCard {
    display: inline-block;
    position: relative;
    height: 35px;
    width: 220px;
    text-align: center;
    line-height: 35px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    .cardText {
      display: inline-block;
      color: #f0eeff;
      width: 190px;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
        border-color: currentColor;
      }
      // text-overflow: ellipsis;
    }
    .close {
      width: 18px;
      height: 18px;
      cursor: pointer;
      float: right;
      position: relative;
      overflow: hidden;
      margin-top: 8px;
      margin-right: 5px;

      &::after {
        width: 100%;
        position: absolute;
        height: 1.5px;
        background: #fff;
        content: '';
        top: 9px;
        left: 0;
        transform: rotate(134deg);
        -ms-transform: rotate(134deg);
        -moz-transform: rotate(134deg);
        -webkit-transform: rotate(134deg);
        -o-transform: rotate(134deg);
      }

      &::before {
        width: 100%;
        position: absolute;
        height: 1.5px;
        background: #fff;
        content: '';
        top: 9px;
        right: 0;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
      }
    }
  }
}
.box {
  display: inline-block;
  width: 100px;
  height: 40px;
  border: 2px solid #1890ff;
  font-size: 16px;
  color: #1890ff;
  text-align: center;
  line-height: 40px;
  margin-left: 30px;
  cursor: pointer;
}
.box-select {
  background-color: #1890ff;
  color: #ffffff;
}
.setSeat {
  .el-input {
    width: 75px;
  }
}
.borderRed {
  border: 1px solid #f56c6c;
  border-radius: 4px;
}
.placeRoomDialog-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 16px;
  font-size: 20px;
}
.edit-icon {
  height: 40px;
  width: 40px;
  border: 1px solid rgb(204, 204, 204);
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: #fff;
  line-height: 40px;
  right: 30px;
  cursor: pointer;
}
::v-deep .addOrEditPlaceRoomForm {
  .el-form-item__error {
    margin-left: 100px;
  }
}
</style>
